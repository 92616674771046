// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-blog-js": () => import("./../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-contact-js": () => import("./../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-privacy-js": () => import("./../src/templates/privacy.js" /* webpackChunkName: "component---src-templates-privacy-js" */),
  "component---src-templates-legal-js": () => import("./../src/templates/legal.js" /* webpackChunkName: "component---src-templates-legal-js" */),
  "component---src-templates-cookies-js": () => import("./../src/templates/cookies.js" /* webpackChunkName: "component---src-templates-cookies-js" */),
  "component---src-templates-terms-js": () => import("./../src/templates/terms.js" /* webpackChunkName: "component---src-templates-terms-js" */),
  "component---src-templates-people-js": () => import("./../src/templates/people.js" /* webpackChunkName: "component---src-templates-people-js" */),
  "component---src-templates-term-post-js": () => import("./../src/templates/term-post.js" /* webpackChunkName: "component---src-templates-term-post-js" */),
  "component---src-templates-people-post-js": () => import("./../src/templates/people-post.js" /* webpackChunkName: "component---src-templates-people-post-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-br-js": () => import("./../src/pages/index.br.js" /* webpackChunkName: "component---src-pages-index-br-js" */),
  "component---src-pages-index-de-js": () => import("./../src/pages/index.de.js" /* webpackChunkName: "component---src-pages-index-de-js" */),
  "component---src-pages-index-es-js": () => import("./../src/pages/index.es.js" /* webpackChunkName: "component---src-pages-index-es-js" */),
  "component---src-pages-index-fr-js": () => import("./../src/pages/index.fr.js" /* webpackChunkName: "component---src-pages-index-fr-js" */),
  "component---src-pages-index-it-js": () => import("./../src/pages/index.it.js" /* webpackChunkName: "component---src-pages-index-it-js" */)
}

